import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";

// component
const JohnRyanArtist = () => {
    // functions

    // on load scroll to top of page
    useEffect(() => { window.scrollTo(0, 0); }, []);
    // constants

    // options for Simple React Lightbox (SRLWrapper)
    const options = {
        buttons: {
            showDownloadButton: false
        }
    };
    // render component
    return (
        <>
            <header>
                {/* artist nav */}
                <nav className="navbar navbar-expand-lg navbar-light text-dark fixed-top shadow-4 blue-background-color">
                    <div className="container">
                        <img className="mx-3 my-1 png-shadow" src="GA_logo.png" width="79" height="77" alt="Granbury Art logo"
                            style={{ marginTop: "-3px" }} />
                        <ul className="navbar-nav me-auto mb-2">
                            <li className="nav-item">
                                <Link to="" className="nav-link font-weight-bold fs-5 text-shadow custom-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link font-weight-bold fs-5 text-shadow custom-link" href="/johnRyanArtist">Top</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>

            <main>
                {/* artist section */}
                <div className="container-fluid py-5 text-dark lightblue-background-color large-margin-top2">

                    <section>

                        <h3 className="text-center font-weight-bold artist-font">John Ryan</h3>

                        <p className="text-center font-weight-bold py-2 text-dark">Painter</p>

                        <p className="text-center mb-5 mx-auto w-responsive">
                            John is a software architect and data engineer by trade.
                            He recently began focusing on oil painting as a way to work through the grief of losing his father.
                            John's style is still evolving but he likes abstract and color focused works.
                        </p>
                        {/* artwork section */}
                        <SRLWrapper options={options}>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3403.webp"
                                        alt="One"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| One</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3404.webp"
                                        alt="Two"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Two</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3423.webp"
                                        alt="14"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| 14</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3408.webp"
                                        alt="Six"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Six</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3407.webp"
                                        alt="Five"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Five</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3406.webp"
                                        alt="Four"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Four</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3405.webp"
                                        alt="Three"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Three</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3410.webp"
                                        alt="Eight"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Eight</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3417.webp"
                                        alt="10"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| 10</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3418.webp"
                                        alt="11"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| 11</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3413.webp"
                                        alt="Nine"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Nine</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3422.webp"
                                        alt="13"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| 13</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3421.webp"
                                        alt="12"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| 12</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\IMG_3409.webp"
                                        alt="Seven"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Seven</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_ryan_artworks\Linus.webp"
                                        alt="Linus"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Linus</p>
                                </div>
                            </div>
                        </SRLWrapper>

                    </section>

                </div>
            </main>

            <footer className="lightblue-background-color">
                <div className="text-center p-3 font-weight-bold" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                    © 2021 Copyright: John Ryan and Jesse Sites
                </div>
            </footer>
        </>
    );
}

export default JohnRyanArtist;