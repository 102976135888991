import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";

// component
const ValeriCranstonArtist = () => {
    // functions

    // on load scroll to top of page
    useEffect(() => { window.scrollTo(0, 0); }, []);
    //constants

    // options for Simple React Lightbox (SRLWrapper)
    const options = {
        buttons: {
            showDownloadButton: false
        }
    };
    // render component
    return (
        <>
            <header>
                {/* artist nav */}
                <nav className="navbar navbar-expand-lg navbar-light text-dark fixed-top shadow-4 blue-background-color">
                    <div className="container">
                        <img className="mx-3 my-1 png-shadow" src="GA_logo.png" width="79" height="77" alt="Granbury Art logo"
                            style={{ marginTop: "-3px" }} />
                        <ul className="navbar-nav me-auto mb-2">
                            <li className="nav-item">
                                <Link to="" className="nav-link font-weight-bold fs-5 text-shadow custom-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link font-weight-bold fs-5 text-shadow custom-link" href="/valeriCranstonArtist">Top</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>

            <main>
                {/* artist section */}
                <div className="container-fluid py-5 text-dark lightblue-background-color large-margin-top2">

                    <section>

                        <h3 className="text-center font-weight-bold artist-font">Valeri Cranston</h3>

                        <p className="text-center font-weight-bold py-2 text-dark">Illustrator/Painter</p>

                        <p className="text-center mb-5 mx-auto w-responsive">
                            Valeri began her journey into art at the age of 7 in piano class. Bored with the lessons she began to draw on the sheet music.
                            A call from the piano teacher to her mother soon got Valeri out of piano class and into an oil painting class.
                            She immersed herself into all things art from that point forward.
                        </p>
                        {/* artwork section */}
                        <SRLWrapper options={options}>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Beach_Dreams.webp"
                                        alt="Beach Dreams"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Beach Dreams</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Spring_Emerging.webp"
                                        alt="Spring Emerging"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Spring Emerging</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/New_Years_Day.webp"
                                        alt="New Year's Day"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| New Year's Day</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Festival.webp"
                                        alt="Festival"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Festival</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Reach_for_the_Sky.webp"
                                        alt="Reach for the Sky"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Reach for the Sky</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Valhalla.webp"
                                        alt="Valhalla"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Valhalla</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Sun_Aspens.webp"
                                        alt="Sun Aspens"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Sun Aspens</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Night_Lights.webp"
                                        alt="Night Lights"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Night Lights</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Desert_Flowers.webp"
                                        alt="Desert Flowers"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Desert Flowers</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Kaleidoscope.webp"
                                        alt="Kaleidoscope"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Kaleidoscope</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Splash.webp"
                                        alt="Splash!"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Splash!</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/From_The_Vine.webp"
                                        alt="From The Vine"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| From The Vine</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Moon_Rose.webp"
                                        alt="Moon Rose"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Moon Rose</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Interstellar_Window.webp"
                                        alt="Interstellar Window"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Interstellar Window</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Wild_Flower.webp"
                                        alt="Wild Flower"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Wild Flower</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Midnight_Beauty.webp"
                                        alt="Midnight Beauty"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Midnight Beauty</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Burst_of_Growth.webp"
                                        alt="Burst of Growth"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Burst of Growth</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Rorshachs_Dream.webp"
                                        alt="Rorshach's Dream"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Rorshach's Dream</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Pick_Me.webp"
                                        alt="Pick Me!"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Pick Me!</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Riot_Flower.webp"
                                        alt="Riot Flower"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Riot Flower</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Sunshower.webp"
                                        alt="Sunshower"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Sunshower</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Fire_and_Ice.webp"
                                        alt="Fire and Ice"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Fire and Ice</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Bloom.webp"
                                        alt="Bloom"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Bloom</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Spirit_Flowers.webp"
                                        alt="Spirit Flowers"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Spirit Flowers</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Peekabo_Cactus.webp"
                                        alt="Peekabo Cactus"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Peekabo Cactus</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Sunset_Glory.webp"
                                        alt="Sunset Glory"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Sunset Glory</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Fall_Rain.webp"
                                        alt="Fall Rain"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Fall Rain</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Symphony_in_Red.webp"
                                        alt="Symphony in Red"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Symphony in Red</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Crowd_of_Cactus.webp"
                                        alt="Crowd of Cactus"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Crowd of Cactus</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Three_Sisters.webp"
                                        alt="Three Sisters"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Three Sisters</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Tropical_Dance.webp"
                                        alt="Tropical Dance"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Tropical Dance</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_cranston_artworks/Primordial.webp"
                                        alt="Primordial"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Primordial</p>
                                </div>
                            </div>
                        </SRLWrapper>

                    </section>

                </div>
            </main>

            <footer className="lightblue-background-color">
                <div className="text-center p-3 font-weight-bold" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                    © 2021 Copyright: John Ryan and Jesse Sites
                </div>
            </footer>
        </>
    );
}

export default ValeriCranstonArtist;