import './App.css';
import { Route, Switch } from 'react-router-dom';
import HomePage from "./homePage";
import JohnRyanArtist from './johnRyanArtist';
import ValeriCranstonArtist from './valeriCranstonArtist';
import SharonUrbanArtist from './sharonUrbanArtist';

// app returning routes to components
function App() {
  return (
    <Switch>
      <Route exact path="/" render={props =>
        <div>
          <HomePage />
        </div>
      } />
      <Route exact path="/johnRyanArtist" component={JohnRyanArtist} />
      <Route exact path="/valeriCranstonArtist" component={ValeriCranstonArtist} />
      <Route exact path="/sharonUrbanArtist" component={SharonUrbanArtist} />
    </Switch>
  );
}
export default App;
