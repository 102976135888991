import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";

// component
const SharonUrbanArtist = () => {
    // functions

    // on load scroll to top of page
    useEffect(() => { window.scrollTo(0, 0); }, []);
    //constants

    // options for Simple React Lightbox (SRLWrapper)
    const options = {
        buttons: {
            showDownloadButton: false
        }
    };
    // render component
    return (
        <>
            <header>
                {/* artist nav */}
                <nav className="navbar navbar-expand-lg navbar-light text-dark fixed-top shadow-4 blue-background-color">
                    <div className="container">
                        <img className="mx-3 my-1 png-shadow" src="GA_logo.png" width="79" height="77" alt="Granbury Art logo"
                            style={{ marginTop: "-3px" }} />
                        <ul className="navbar-nav me-auto mb-2">
                            <li className="nav-item">
                                <Link to="" className="nav-link font-weight-bold fs-5 text-shadow custom-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link font-weight-bold fs-5 text-shadow custom-link" href="/sharonUrbanArtist">Top</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>

            <main>
                {/* artist section */}
                <div className="container-fluid py-5 text-dark lightblue-background-color large-margin-top2">

                    <section>

                        <h3 className="text-center font-weight-bold artist-font">Sharon Urban</h3>

                        <p className="text-center font-weight-bold py-2 text-dark">Painter</p>

                        <p className="text-center mb-5 mx-auto w-responsive">
                            With an eye for blended perspectives and color, Sharon Urban creates lasting memories of special places and moments both in landscapes and portraits.
                            She has been painting for decades, first inspired as a teenager by an abstract painting.
                            Willing to tackle challenges, she enjoys making composite scenes from special travel photos.
                            Sharon works with a variety of mediums and projects, from canvases to walls and furniture.
                        </p>
                        {/* artwork section */}
                        <SRLWrapper options={options}>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Mesmerizing_Morocco.webp"
                                        alt="Mesmerizing Morocco"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Mesmerizing Morocco</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Tromp-l'oeil.webp"
                                        alt="Tromp-l'oeil"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Tromp-l'oeil</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Altar_Rock_Yosemite_according_to_Miur.webp"
                                        alt="Altar Rock, Yosemite, according to Miur"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Altar Rock, Yosemite, according to Miur</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Interlaken_Switzerland.webp"
                                        alt="Interlaken Switzerland"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Interlaken Switzerland</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Bonny_Scotland.webp"
                                        alt="Bonny Scotland"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Bonny Scotland</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Cilley_Bridge.webp"
                                        alt="Cilley Bridge"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Cilley Bridge</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Lilac_Breasted_Rollers_Africa.webp"
                                        alt="Lilac Breasted Rollers, Africa"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Lilac Breasted Rollers, Africa</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Evening_in_Venice.webp"
                                        alt="Evening in Venice"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Evening in Venice</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Aare_River_Switzerland.webp"
                                        alt="Aare River, Switzerland"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Aare River, Switzerland</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Quiet.webp"
                                        alt="Quiet"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Quiet</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Isolated_Abundance.webp"
                                        alt="Isolated Abundance"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Isolated Abundance</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Safe_Harbor.webp"
                                        alt="Safe Harbor"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Safe Harbor</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Texas_Beauties.webp"
                                        alt="Texas Beauties"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Texas Beauties</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Rampant_Fun.webp"
                                        alt="Rampant Fun"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Rampant Fun</p>
                                </div>
                                <div className="col-lg-3 mb-4">
                                    <img src="GA_urban_artworks\Expressions.webp"
                                        alt="Expressions"
                                        className="w-100 shadow-1-strong rounded" />
                                    <p className="font-weight-bold mt-4">| Expressions</p>
                                </div>
                            </div>
                        </SRLWrapper>

                    </section>

                </div>
            </main>

            <footer className="lightblue-background-color">
                <div className="text-center p-3 font-weight-bold" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                    © 2021 Copyright: John Ryan and Jesse Sites
                </div>
            </footer>
        </>
    );
}

export default SharonUrbanArtist;