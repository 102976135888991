import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// component
const HomePage = () => {
  // functions

  // on load scroll to top of page
  useEffect(() => { window.scrollTo(0, 0); }, []);
  // render component
  return (
    <>
      <header>
        {/* main nav */}
        <nav className="navbar navbar-expand-lg navbar-light text-dark fixed-top shadow-4 blue-background-color">
          <div className="container">
            <img className="mx-3 my-1 png-shadow" src="GA_logo.png" width="79" height="77" alt="Granbury Art logo"
              style={{ marginTop: "-3px" }} />
            <ul className="navbar-nav me-auto mb-2">
              <li className="nav-item">
                <a className="nav-link font-weight-bold fs-5 text-shadow custom-link" href="/">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-bold fs-5 text-shadow custom-link" href="#artists">Artists</a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-bold fs-5 text-shadow custom-link" href="#about">About</a>
              </li>
            </ul>
          </div>
        </nav>
        {/* title area */}
        <div id="home" className="bg-image shadow-4 large-margin-top" style={{
          backgroundImage: "url(https://images.pexels.com/photos/998065/pexels-photo-998065.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
          height: "200px"
        }}>
          <div className="mask text-white" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
            <div className="container d-flex align-items-center justify-content-center text-center h-100">
              <div className="text-white">
                <h1 className="mb-3 font-weight-bold pt-5 text-uppercase logo-animation">Granbury Art</h1>
                <h2 className="mb-4 cursive-font text-shadow">Original, high-quality artworks from Granbury, Texas.</h2>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* content */}
      <main className="lightblue-background-color">
        <div className="container">
          <section>
            <div className="row gx-4 pt-5">
              <div className="col-md-5 mb-4">
                <div className="bg-image hover-overlay ripple shadow-2-strong" data-mdb-ripple-color="light">
                  <img src="GA_cranston_artworks/Midnight_Beauty.webp" className="img-fluid" alt="Midnight Beauty" />
                  <Link to="/valeriCranstonArtist">
                    <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                  </Link>
                </div>
              </div>
              {/* featured artist banner */}
              <div className="col-md-6 mb-4">
                <span className="badge bg-danger px-2 py-1 shadow-2-strong mb-3">Featured Artist</span>
                <Link to="/valeriCranstonArtist">
                  <h4 className="font-weight-bold link-animation"><strong>Valeri Cranston</strong></h4>
                  <p className="text-dark mt-3">
                    <strong>Midnight Beauty</strong>
                  </p>
                </Link>
              </div>
            </div>
            <div id="artists"></div>
          </section>

          <hr className="my-5" />

          <section>
            {/* list of artists */}
            <div className="row gx-4">
              {/* artist area */}
              <div className="col-lg-4 mb-4">
                <div>
                  <div className="bg-image hover-overlay rounded ripple mb-4" data-mdb-ripple-color="light">
                    <img src="GA_ryan_artworks\IMG_3408.webp" className="img-fluid shadow-1-strong" alt="Six" />
                    <Link to="/johnRyanArtist">
                      <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                    </Link>
                  </div>
                  <Link to="/johnRyanArtist">
                    <p className="text-dark text-center">
                      <strong>Six</strong>
                    </p>
                  </Link>

                  <hr className="my-4" />

                  <Link to="/johnRyanArtist" className="text-dark">
                    <h5 className="font-weight-bold link-animation">John Ryan</h5>

                    <div className="mb-3">
                      <div className="text-dark font-weight-bold mt-3">
                        Painter
                      </div>
                    </div>

                    <p>
                      John is a software architect and data engineer by trade.
                      He recently began focusing on oil painting as a way to work through the grief of losing his father.
                      John's style is still evolving but he likes abstract and color focused works.
                    </p>
                  </Link>

                  <hr />

                  <Link to="/johnRyanArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_ryan_artworks\IMG_3403.webp"
                          className="img-fluid shadow-1-strong rounded" alt="One" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>One</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <hr className="my-4" />

                  <Link to="/johnRyanArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_ryan_artworks\IMG_3404.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Two" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Two</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <hr className="my-4" />

                  <Link to="/johnRyanArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_ryan_artworks\IMG_3405.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Three" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Three</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <hr className="my-4" />

                  <Link to="/johnRyanArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_ryan_artworks\IMG_3406.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Four" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Four</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              {/* artist area */}
              <div className="col-lg-4 mb-4">
                <div>
                  <div className="bg-image hover-overlay ripple rounded mb-4" data-mdb-ripple-color="light">
                    <img src="GA_cranston_artworks/Beach_Dreams.webp" className="img-fluid shadow-1-strong" alt="Beach Dreams" />
                    <Link to="/valeriCranstonArtist">
                      <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                    </Link>
                  </div>
                  <Link to="/valeriCranstonArtist">
                    <p className="text-dark text-center">
                      <strong>Beach Dreams</strong>
                    </p>
                  </Link>

                  <hr className="my-4" />

                  <Link to="/valeriCranstonArtist" className="text-dark">
                    <h5 className="font-weight-bold link-animation">Valeri Cranston</h5>

                    <div className="mb-3">
                      <div className="text-dark font-weight-bold mt-3">
                        Illustrator/Painter
                      </div>
                    </div>

                    <p>
                      Valeri began her journey into art at the age of 7 in piano class. Bored with the lessons she began to draw on the sheet music.
                      A call from the piano teacher to her mother soon got Valeri out of piano class and into an oil painting class.
                      She immersed herself into all things art from that point forward.
                    </p>
                  </Link>

                  <hr />

                  <Link to="/valeriCranstonArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_cranston_artworks/Bloom.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Bloom" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Bloom</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <hr className="my-4" />

                  <Link to="/valeriCranstonArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_cranston_artworks/Burst_of_Growth.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Burst of Growth" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Burst of Growth</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <hr className="my-4" />

                  <Link to="/valeriCranstonArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_cranston_artworks/Crowd_of_Cactus.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Crowd of Cactus" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Crowd of Cactus</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <hr className="my-4" />

                  <Link to="/valeriCranstonArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_cranston_artworks/Desert_Flowers.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Desert Flowers" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Desert Flowers</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              {/* artist area */}
              <div className="col-lg-4 mb-4">
                <div>
                  <div className="bg-image hover-overlay rounded ripple mb-4" data-mdb-ripple-color="light">
                    <img src="GA_urban_artworks\Evening_in_Venice.webp" className="img-fluid shadow-1-strong" alt="Evening in Venice" />
                    <Link to="/sharonUrbanArtist">
                      <div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}></div>
                    </Link>
                  </div>
                  <Link to="/sharonUrbanArtist">
                    <p className="text-dark text-center">
                      <strong>Evening in Venice</strong>
                    </p>
                  </Link>

                  <hr className="my-4" />

                  <Link to="/sharonUrbanArtist" className="text-dark">
                    <h5 className="font-weight-bold link-animation">Sharon Urban</h5>

                    <div className="mb-3">
                      <div className="text-dark font-weight-bold mt-3">
                        Painter
                      </div>
                    </div>

                    <p>
                      With an eye for blended perspectives and color, Sharon Urban creates lasting memories of special places and moments both in landscapes and portraits.
                      She has been painting for decades, first inspired as a teenager by an abstract painting.
                      Willing to tackle challenges, she enjoys making composite scenes from special travel photos.
                      Sharon works with a variety of mediums and projects, from canvases to walls and furniture.
                    </p>
                  </Link>

                  <hr />

                  <Link to="/sharonUrbanArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_urban_artworks\Aare_River_Switzerland.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Aare River, Switzerland" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Aare River, Switzerland</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <hr className="my-4" />

                  <Link to="/sharonUrbanArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_urban_artworks\Altar_Rock_Yosemite_according_to_Miur.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Altar Rock, Yosemite, according to Miur" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Altar Rock, Yosemite, according to Miur</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <hr className="my-4" />

                  <Link to="/sharonUrbanArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_urban_artworks\Bonny_Scotland.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Bonny Scotland" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Bonny Scotland</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                  <hr className="my-4" />

                  <Link to="/sharonUrbanArtist" className="text-dark">
                    <div className="row mb-4 pb-2">
                      <div className="col-3">
                        <img src="GA_urban_artworks\Cilley_Bridge.webp"
                          className="img-fluid shadow-1-strong rounded" alt="Cilley Bridge" />
                      </div>

                      <div className="col-9">
                        <p className="mb-2">
                          <strong>Cilley Bridge</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>

        <hr className="my-5" />
        {/* about section */}
        <section>
          <div id="about" className="text-center text-dark pb-2">
            <h3 className="mb-5 font-weight-bold">About Granbury Art</h3>

            <p className="mb-5 mx-auto w-responsive">
              Granbury Art is website for the artists of Granbury, Texas to host their beautiful creations for the world to see.
              The art here is not restricted to any form or medium, and is completely original and uniqiue. However,
              all art does belong to the artists and permission must be given by them to use any of the artworks hosted here.
            </p>
          </div>
        </section>
      </main>

      <footer className="lightblue-background-color">
        <div className="text-center p-3 font-weight-bold" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          © 2021 Copyright: John Ryan and Jesse Sites
        </div>
      </footer>
    </>
  );
}

export default HomePage;